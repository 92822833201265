import { ApolloClient, InMemoryCache, from, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { clearUserData, loadStateFn } from '../../utils/localStorage'
import { equal } from '../../utils/javascript'

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_BASE_URL })

// eslint-disable-next-line consistent-return
const getToken = async () => {
  const token =
    (await loadStateFn('projectToken')) || loadStateFn('token') || null
  if (token) {
    return token
  }
}

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization:
      loadStateFn('forgetPassToken') ||
      loadStateFn('projectToken') ||
      loadStateFn('token') ||
      null,
    accessToken: loadStateFn('accessToken') || null,
    realmId: loadStateFn('realmId') || null,
    refreshToken: loadStateFn('refreshToken') || null,
  },
}))

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SOCKET_URL,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = await getToken()
      return {
        authorization: token || '',
      }
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (err.extensions.code === 'UNAUTHENTICATED') {
        // console.log(`graphQLErrors123`, graphQLErrors)
        clearUserData()
        window.location.replace(`${window.location.origin}/login`)
      }
    })
  }

  if (
    networkError?.name === 'TypeError' &&
    networkError?.message === 'Failed to fetch'
  ) {
    clearUserData()
    window.location.replace(`${window.location.origin}/network-error`)
    // console.log(' networkError?.name', networkError?.name)
  }
  // if (networkError) {
  //   console.log(`[Network error]: ${networkError}`)
  //   console.log(`[Network error]: ${networkError.name}`)
  //   console.log(`[Network error]: ${networkError.stack}`)
  //   console.log(`[Network error]: ${networkError.message}`)
  // }
  return ''
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      equal(definition.kind, 'OperationDefinition') &&
      equal(definition?.operation, 'subscription')
    )
  },
  wsLink,
  authLink.concat(uploadLink),
)

export const apolloClient = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(),
})

export default {
  apolloClient,
}
